import JSONData from "../../../content/serviceCardsData.json";
import ServiceCard from "./serviceCard";
import React, {ReactElement} from "react";
import * as styles from "./servicesCardList.module.scss"
import {Col, Row} from "react-bootstrap";

export default function ServicesCardList() {
    return <Row className={styles.cardListWrapper}>
        {JSONData.cards.map((data: any, idx: number) => {
            const CardImage = require(`../../svg/pointers/${data.iconName}`).default as ReactElement;
            return <Col key={idx} className={styles.cardWrapper} md={{offset: idx % 2 === 0 ? 1 : 0, span: 5}}
                        lg={{offset: 0, span: 3}}><ServiceCard linkTo={data.link} header={data.title} text={data.text}
                                                               icon={<CardImage/>}/></Col>
        })}</Row>
}
