// extracted by mini-css-extract-plugin
export var buttonWrapper = "whoWeAre-module--buttonWrapper--fbcd2";
export var header = "whoWeAre-module--header--a66a7";
export var imageHideMobile = "whoWeAre-module--imageHideMobile--6ab2a";
export var imageHigher = "whoWeAre-module--imageHigher--77dd9";
export var images = "whoWeAre-module--images--d3044";
export var sectionTitle = "whoWeAre-module--sectionTitle--607df";
export var stat = "whoWeAre-module--stat--4401f";
export var statText = "whoWeAre-module--statText--d96f5";
export var statWrapper = "whoWeAre-module--statWrapper--7b71a";
export var statsWrapper = "whoWeAre-module--statsWrapper--9d60d";
export var text = "whoWeAre-module--text--6b317";