import React, {ReactElement} from "react"
import * as styles from "./serviceCard.module.scss"
import RedRightArrowSVG from "../../svg/redRightArrow.svg";
import {Link} from "gatsby";

export default function ServiceCard(props: ServiceCardComponentProps) {

    return <Link to={props.linkTo ? props.linkTo : "/404"} className={styles.cardWrapper}>
        <div className={styles.icon}>
            {props.icon}
        </div>
        <div className={styles.header}>
            {props.header}
        </div>
        <div className={styles.text}>
            {props.text}
        </div>
        <div className={styles.arrow}>
            <RedRightArrowSVG/>
        </div>
    </Link>
}

interface ServiceCardComponentProps {
    header: string,
    text: string,
    icon: ReactElement,
    linkTo?: string;
}
