import React from "react";
import {Col, Row} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import * as styles from "./expertsSection.module.scss"
import JSONData from "../../../../content/expertsSectionData.json"
import ListBullet from "../../../svg/listBullet.svg";

export default function ExpertsSection() {

    const data = useStaticQuery(graphql`{
    imageDesktop: file(relativePath: {eq: "our-services/experts.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: CONSTRAINED)
      }
    }
  }
  `);

    return <Row>
        <Col className={styles.photoCol} xs={{span: 12}} md={{offset: 1, span: 10}} lg={{offset: 1, span: 5}}>
            <GatsbyImage image={data.imageDesktop.childImageSharp.gatsbyImageData} alt={"experts"} objectFit={"contain"}
                         className={styles.photoWrapper}/>
        </Col>
        <Col className={styles.infoWrapper} xs={{span: 12}} md={{offset: 1, span: 10}} lg={{span: 5}}>
            <h1 className={styles.header}>
                {JSONData.title}
            </h1>
            <div className={styles.text}>
                {JSONData.text}
            </div>
            <div className={styles.listWrapper}>
                {JSONData.listContent.map((data: any, idx: number) => <ListItem key={idx} text={data.item}/>)}
            </div>
        </Col>
    </Row>
}

function ListItem(props: { text: string }) {
    return <div className={styles.listItemWrapper}>
        <div>
            <ListBullet/>
        </div>
        <div className={styles.listItemText}>
            {props.text}
        </div>
    </div>
}
